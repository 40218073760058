<template>
  <v-container fluid class="invoices-page">
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <div :id="window.width < 600 ? 'invoices-page-step-0' : ''"></div>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.invoicesByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="invoicesHeaders"
            :disable-sort="tourActive"
            :class="tourActive ? 'no-pointers' : ''"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="invoices"
            :search="searchString"
            class="elevation-1"
            multi-sort>
            <template v-slot:item.paid="{ item }">
              <v-icon :color="item.paid ? 'green' : 'red'">{{ item.paid ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </template>
            <template v-slot:item.total_price="{ item }">
              {{ item.currency.symbol + item.total_price + (item?.total_vat ? ` (+${item.total_vat})` : '') }}
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.invoices') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  id="invoices-page-step-1"
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <p :id="window.width > 599 ? 'invoices-page-step-0' : ''" class="first-step"></p>

                <v-spacer />

                <v-dialog v-if="user.role_id != 11" v-model="invoiceDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on" id="invoices-page-step-2">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(FormTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(saveInvoice)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="client">
                                  <v-autocomplete
                                    v-model="editedItem.client_id"
                                    :items="clients"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('invoicesPage.chooseClient')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    :error-messages="errors"
                                    @change="handleClientChange"
                                    @focus="$event.target.click()"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="" v-slot="{ errors }" name="currency">
                                  <v-autocomplete
                                    v-model="editedItem.currency_id"
                                    :items="currencies"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('subscriptionsPage.currency')"
                                    :error-messages="errors"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="9" md="9" sm="9">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="drivings">
                                  <v-autocomplete
                                    multiple
                                    ref="chooseDrivings"
                                    v-model="editedItem.driving_ids"
                                    :items="drivings"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    :disabled="!editedItem.client_id"
                                    :label="$t('invoicesPage.chooseDrivings')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"
                                    @change="onDrivingSelect">
                                    <!-- Customizing the display of selected items -->
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="index > 0" class="mr-1"> , </span>
                                      <span :key="index">
                                        {{ item.number }}
                                        <!-- Display the number instead of name here -->
                                      </span>
                                    </template>
                                  </v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="3" md="3" sm="3">
                                <v-checkbox
                                  v-model="editedItem.all"
                                  color="success"
                                  @change="handleAllCheckboxChange"
                                  :label="$t('invoicesPage.allDrivings')"></v-checkbox>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider
                                  style="width: inherit"
                                  rules="max:200"
                                  v-slot="{ errors }"
                                  name="invoice_number"
                                  ref="numberRef">
                                  <v-text-field
                                    type="text"
                                    name="invoice_number"
                                    v-model="editedItem.number"
                                    clearable
                                    counter="200"
                                    :label="$t('invoicesPage.invoiceNumber')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <v-menu
                                  ref="payUntilDate"
                                  v-model="payUntilDate"
                                  :close-on-content-click="false"
                                  :return-value.sync="editedItem.pay_until"
                                  min-width="auto"
                                  offset-y
                                  transition="scale-transition">
                                  <template v-slot:activator="{ on, attrs }">
                                    <validation-provider rules="" v-slot="{ errors }" name="pay_until">
                                      <v-text-field
                                        v-model="editedItem.pay_until"
                                        clearable
                                        :label="$t('invoicesPage.payUntil')"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                  </template>
                                  <DatePicker
                                    v-model="editedItem.pay_until"
                                    :model-config="modelConfig"
                                    mode="date"
                                    :first-day-of-week="firstDay"
                                    @input="$refs.payUntilDate.save(editedItem.pay_until)">
                                  </DatePicker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="invoiceDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ editedItem.number }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('invoicesPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn :disabled="!enableSave" class="primary" text @click="deleteItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item, index }">
              <td class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn text v-on="{ ...on, ...tooltipOn }" :id="index === 0 ? 'invoices-page-step-3' : ''">
                          <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('buttons.actions') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="downloadInvoice(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-download</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.downloadFile') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>
                        {{ $t('tooltips.downloadFile') }}
                      </span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <div style="display: block" v-bind="attrs" v-on="on">
                          <v-list-item :disabled="disableGeneratePaymentLink" @click="generatePaymentLink(item.id)">
                            <v-icon class="mx-1" small v-bind="attrs">mdi-link-variant</v-icon>
                            <v-list-item-title class="ml-2">
                              {{ $t('buttons.generatePaymentLink') }}
                            </v-list-item-title>
                          </v-list-item>
                        </div>
                      </template>
                      <span v-if="disableGeneratePaymentLink">
                        {{ $t('tooltips.cannotGeneratePaymentLInk') }}
                      </span>
                      <span v-else>
                        {{ $t('tooltips.generatePaymentLink') }}
                      </span>
                    </v-tooltip>

                    <v-tooltip v-if="user.role_id != 11" left>
                      <template v-slot:activator="{ on, attrs }">
                        <div style="display: block" v-bind="attrs" v-on="on">
                          <v-list-item @click="openConfirmModal(item)" :disabled="item.paid === 1">
                            <v-icon class="mx-1" small v-bind="attrs">mdi-cash-check</v-icon>
                            <v-list-item-title class="ml-2">
                              {{ $t('buttons.markInvoiceAsPaid') }}
                            </v-list-item-title>
                          </v-list-item>
                        </div>
                      </template>
                      <span v-if="item.paid">
                        {{ $t('tooltips.invoiceIsPaid') }}
                      </span>
                      <span v-else>
                        {{ $t('tooltips.markInvoiceAsPaid') }}
                      </span>
                    </v-tooltip>

                    <v-tooltip v-if="user.role_id != 11" left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="deleteItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.delete') }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="markAsPaidDialog" max-width="650">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">
          {{ editedItem.number }}
          <v-spacer />
          <v-icon class="mdi mdi-close" style="color: white" @click="closeConfirmModal"></v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <p>
              {{ $t('invoicesPage.markInvoiceAsPaid') }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary" text @click="markInvoiceAsPaid">
            {{ $t('buttons.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="tourStyle">
      <v-tour name="invoicesTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { invoicesHeaders } from '@/mixins/data-table-headers';
import { defaultInvoice } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDateTime } from '@/utils/formatDate';
import { getAllTourSteps, invoicesPageSteps } from '@/mixins/onboarding-tutorial-steps';
import { DatePicker } from 'v-calendar/src/components';
import { mapState } from 'vuex';

export default {
  name: 'InvoicesPage',
  components: { ButtonSubmit, DatePicker },
  data() {
    return {
      searchString: '',
      invoiceDialog: false,
      invoiceDialogDelete: false,
      invoices: [],
      FormTitle: 'invoicesPage.newItem',
      editedItem: {},
      enableSave: true,
      loading: false,
      drivings: [],
      user: {},
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
      markAsPaidDialog: false,
      payUntilDate: null,
      modelConfig: {
        type: 'string',
        mask: 'DD-MM-YYYY',
      },
    };
  },
  created() {
    this.user = this.$store.getters['auth/user'];
    this.editedItem = Object.assign({}, defaultInvoice);
    this.loadAllInvoices();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    invoicesHeaders() {
      return invoicesHeaders(i18n, { hiddenColumns: this.user?.role_id == 11 ? ['client.name'] : [] });
    },
    disableGeneratePaymentLink() {
      return !this.user?.organisation?.organisation_settings?.stripe_connect_enabled;
    },
    currencies() {
      return this.$store.getters['currencies/getOrganisationCurrencies']
        .map((item) => {
          return {
            ...item.currency,
          };
        })
        .filter((item) => parseInt(item.active) === 1);
    },
    clients() {
      return this.$store.getters['clients/getClients'].filter((client) => parseInt(client.active) === 1);
    },
  },
  methods: {
    async loadAllInvoices() {
      let path = 'invoices/getAllInvoices';

      if (this.user.role_id == 11) {
        path = 'customers/getAllInvoices';
      }

      await this.$store.dispatch(path).then((res) => {
        this.invoices = res.data.map((e) => {
          e.active = parseInt(e.active);
          e.default = parseInt(e.default);
          e.created_at = formatDateTime(e.created_at);
          e.drivings = e.invoiceHasDrivings.map((driving) => {
            return ' ' + driving?.driving?.number;
          });
          return e;
        });
      });

      this.setStepsAndStartTour();
    },

    deleteItem(item) {
      this.editedItem = this.invoices.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.invoiceDialogDelete = true;
    },

    async saveInvoice() {
      this.enableSave = false;
      this.loading = true;
      let path = 'saveInvoice';

      await this.$store
        .dispatch('invoices/' + path, this.editedItem)
        .then((res) => {
          this.loadAllInvoices();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeEdit();
          this.downloadInvoice(res.data);
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response.data?.code && error.response.data?.code === 'INVOICE-0013') {
              this.$refs.numberRef.applyResult({
                errors: [
                  i18n.t('customValidations.fieldUnique', {
                    field: i18n.t('names.invoice_number'),
                  }),
                ],
              });
            } else {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
            }
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
          this.FormTitle = 'invoicesPage.newItem';
        });
    },

    async deleteItemConfirm() {
      await this.$store
        .dispatch('invoices/deleteInvoice', this.editedItem)
        .then((res) => {
          if (res.success) {
            this.loadAllInvoices();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeDelete();
        });
    },

    closeEdit() {
      this.invoiceDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultInvoice);
      });
      this.$refs.form.reset();
      this.FormTitle = 'invoicesPage.newItem';
    },

    closeDelete() {
      this.invoiceDialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultInvoice);
      });
    },

    downloadInvoice(item) {
      window.open(item.file_path, '_blank');
    },

    generatePaymentLink(id) {
      this.$store
        .dispatch('invoices/generateInvoicePaymentLink', { invoice_id: id })
        .then((res) => {
          window.open(res.data, '_blank');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        });
    },

    openConfirmModal(item) {
      this.editedItem = Object.assign({}, item);
      this.markAsPaidDialog = true;
    },

    closeConfirmModal() {
      this.markAsPaidDialog = false;
      this.editedItem = Object.assign({}, defaultInvoice);
    },

    markInvoiceAsPaid() {
      this.$store
        .dispatch('invoices/markInvoiceAsPaid', { invoice_id: this.editedItem.id })
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.loadAllInvoices();
          this.closeConfirmModal();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        });
    },

    async loadAllDrivings() {
      const data = { client_id: this.editedItem.client_id, all: this.editedItem.all };
      await this.$store
        .dispatch('drivings/getAllDrivingsForInvoices', data)

        .then((res) => {
          this.drivings = res.data.map((e) => {
            if (e.currency.symbol && e.price) {
              e.name = e?.number + ' - ' + formatDateTime(e?.pickup_time) + ' - ' + e?.currency?.symbol + e?.price;
            } else {
              e.name = e?.number + ' - ' + formatDateTime(e?.pickup_time);
            }
            return e;
          });
        });
    },

    onDrivingSelect() {
      // on driving select we must remove search string
      this.$refs.chooseDrivings.lazySearch = '';
    },

    getClientsName(id) {
      if (id) {
        let index = this.clients.findIndex((x) => x.id == id);
        return this.clients[index]?.name;
      } else {
        return '';
      }
    },

    handleClientChange() {
      if (this.editedItem.client_id) {
        this.editedItem.driving_ids = [];
        this.loadAllDrivings();
      }
    },

    handleAllCheckboxChange() {
      if (this.editedItem.all) {
        this.editedItem.driving_ids = this.drivings.map((option) => option.id);
      } else {
        this.editedItem.driving_ids = [];
      }
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '5')
            ?.map((item) => item.step_id);
          this.steps = invoicesPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['invoicesTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.invoiceDialog = false;
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '5',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '5',
        step_id: `#invoices-page-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '5',
            step_id: `#invoices-page-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },

  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },

  watch: {
    invoiceDialog(val) {
      val || this.closeEdit();
    },
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style>
.invoices-page {
  .no-pointers {
    pointer-events: none;
  }

  .v-step {
    inset: 0 0 auto -10px !important;
  }

  .first-step {
    margin-top: 250px;
  }
}
</style>
